import {atom, useAtom} from 'jotai';
import {useEffect} from 'react';

export const segmentAnonymousIdAtom = atom(null);

const useAnalytics = () => {
  const [segmentAnonymousId, setSegmentAnonymousId] = useAtom(
    segmentAnonymousIdAtom,
  );

  const isSegmentInitialized = Boolean(window?.analytics?.initialized);

  useEffect(() => {
    const getOrCreateAnyonymousId = window?.analytics?.user?.().anonymousId;

    if (
      isSegmentInitialized &&
      getOrCreateAnyonymousId &&
      !segmentAnonymousId
    ) {
      setSegmentAnonymousId(getOrCreateAnyonymousId());
    }
  }, [isSegmentInitialized]);

  return {isReady: isSegmentInitialized, segmentAnonymousId};
};

export default useAnalytics;
