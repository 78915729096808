import React, {useContext, lazy, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getPrice, getImage} from '../../../../utils/registryItem';
import useGreetingCardExperiment from './hooks/useGreetingCardExperiment';
import {composeClasses} from './ProductModalConfig';
import {ModalContext} from '../../contexts/ModalContext';

const GuestCashFund = lazy(
  () => import('@tkww/registry-component-guest-cash-fund'),
);

const GuestCashFundContent = ({
  application,
  currentProduct,
  coupleId,
  fianceFirstName,
  firstName,
  cartRedirectPath,
  cart,
  showCartLeakageAlert,
  memberId,
  trackCashFund,
}) => {
  const {greetingCardOptions, isControl} = useGreetingCardExperiment();
  const {setProps} = useContext(ModalContext);

  useEffect(() => {
    setProps((previousProps) => ({
      ...previousProps,
      classes: composeClasses({isVariantCashFund: !isControl}),
    }));
  }, [isControl]);

  return (
    <GuestCashFund
      cashFundId={currentProduct.cashFundId}
      coupleId={coupleId}
      description={currentProduct.description}
      fundType={currentProduct.fundType}
      image={getImage(currentProduct)}
      isGoalHidden={currentProduct.isGoalHidden}
      isPurchased={currentProduct.fulfilled}
      name={currentProduct.name}
      numReceived={currentProduct.numReceived}
      numRequested={currentProduct.numRequested}
      price={getPrice(currentProduct)}
      fianceFirstName={fianceFirstName}
      firstName={firstName}
      cartRedirectPath={cartRedirectPath}
      showCartLeakageAlert={showCartLeakageAlert}
      topChoice={currentProduct.topChoice}
      cartItems={cart}
      memberId={memberId}
      trackCashFund={trackCashFund}
      cardExperimentOptions={greetingCardOptions}
      application={application}
    />
  );
};

GuestCashFundContent.defaultProps = {
  cart: [],
  fianceFirstName: '',
  firstName: '',
  memberId: '',
};

GuestCashFundContent.propTypes = {
  application: PropTypes.string.isRequired,
  coupleId: PropTypes.string.isRequired,
  memberId: PropTypes.string,
  fianceFirstName: PropTypes.string,
  firstName: PropTypes.string,
  cartRedirectPath: PropTypes.string.isRequired,
  showCartLeakageAlert: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(PropTypes.shape({})),
  currentProduct: PropTypes.shape({
    cashFundId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fundType: PropTypes.string.isRequired,
    isGoalHidden: PropTypes.bool.isRequired,
    fulfilled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    numReceived: PropTypes.number.isRequired,
    numRequested: PropTypes.number.isRequired,
    topChoice: PropTypes.string.isRequired,
  }).isRequired,
  trackCashFund: PropTypes.func.isRequired,
};

export default GuestCashFundContent;
