export const {
  ENV,
  NODE_ENV,
  WEBUI_HOST,
  WWS_SEGMENT_KEY,
  TRANSACTIONAL_ITEMS_API_HOST,
  UNLEASH_PROXY_HOST,
  UNLEASH_CLIENT_KEY,
} = process.env;

export const AMOUNT_NEEDED_PURCHASED = 'Purchased';
export const AMOUNT_NEEDED_UNAVAILABLE = 'Out of Stock';
export const AMOUNT_NEEDED_UNLIMITED = 'Gift any amount';
export const GVR_HB_APPLICATION_NAME = 'gvr';
export const APPLICATION_GVR = 'guest facing registry';
export const APPLICATION_WWS = 'wedding website';
export const APPLICATION_WWS_OLD_TEMPLATE = 'wedding website - old template';
export const CASH_FUND = 'CASH_FUND';
export const INTERNAL_GIFT_CARD = 'INTERNAL_GIFT_CARD';
export const CASH_REGISTRY_TYPE = 3;
export const COUPLE_NAME_LIMIT = 15;
export const DEFAULT_MAX_SORT_ORDER = 1000;
export const FEED_REGISTRY_TYPE = 1;
export const MANUAL_REGISTRY_TYPE = 2;
export const PREVIEW_WEDDING_WEBSITE_PATHNAME = '/gs/dashboard/preview';
export const RETAILER_PRODUCT = 'RETAILER_PRODUCT';
export const SOURCE_AFFILIATE_ID_REGISTRY = 994;
export const SOURCE_AFFILIATE_ID_WWS = 995;
export const SUBTYPE_DEFAULT = 'physical product';
export const SUBTYPE_EXPERIENCE = 'Experiences';
export const SUBTYPE_GIFT_CARD = 'gift card';
export const FIRST_CASH_FUND_ID = 'first-cash-fund';
export const TRANSACTIONAL_PRODUCT = 'TRANSACTIONAL_PRODUCT';
export const UNIVERSAL_REGISTRY_PRODUCT = 'UNIVERSAL_REGISTRY_PRODUCT';
export const UNIVERSAL_REGISTRY_RETAILER_ID = 20000;
export const UNLEASH_REFRESH_INTERVAL_SECS = 30;
export const FULFILLED_INFO_SOURCE = 'Marked purchased by guest';
export const HELP_CENTER_URL = 'https://theknot.zendesk.com';
export const GIFT_INTEGRATION_TYPES = {
  UNIVERSAL: 'universal',
  AFFILIATE_RETAILER: 'affiliate retailer',
  TKRS: 'tkrs',
};

export const CASH_FUND_TYPES = {
  FIXED: 'fixed',
  UNLIMITED: 'unlimited',
};
export const MARK_FULFILLED_POPUP_FORM_MODAL = {
  PURCHASE_REMIND: 'purchase remind',
  ERROR_MODAL: 'error modal',
  PDP_MODAL: 'pdp modal',
};

const UNLEASH_APP_NAME = 'fe-guest-registry';

export const UNLEASH_CONFIG = {
  url: `${UNLEASH_PROXY_HOST}/proxy`,
  clientKey: UNLEASH_CLIENT_KEY,
  refreshInterval: UNLEASH_REFRESH_INTERVAL_SECS,
  appName: UNLEASH_APP_NAME,
  /*
   * define the bootstrap from the main unleash-proxy-client IToggle interface
   *
   * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#LL37C17-L37C24
   * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L54-L59
   */
  bootstrap: [
    {
      enabled: true,
      name: `${UNLEASH_APP_NAME}-offline`,
      /* use the defaultVariant from the main unleash-proxy-client IVariant interface
       *
       * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L45-L52
       * @link https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts#L74
       */
      variant: {
        name: 'disabled',
        enabled: false,
      },
    },
  ],
};

export const IS_PRODUCTION = ENV === 'production';

export const BASE_URL = IS_PRODUCTION
  ? 'https://www.theknot.com'
  : 'https://qa-beta.theknot.com';
export const CORE_API_HOST = IS_PRODUCTION
  ? 'prod-core-api.regsvcs.theknot.com'
  : 'qa-core-api.regsvcs.theknot.com';
export const DEFAULT_IMAGE_ID_PG = IS_PRODUCTION
  ? 'e4e0c8eb-93f0-48bf-8c10-f0b5b1e3efa7'
  : '4d5bd5f5-0636-47e4-a762-8c5973e4f85d';
export const DEFAULT_IMAGE_ID_RL = IS_PRODUCTION
  ? '5fa481ef-43bb-43ae-aab3-665614c9d774'
  : '4fe998a4-88b0-4777-b8c8-16988f6e65be';
export const EXPERIMENTATION_API_HOST = IS_PRODUCTION
  ? 'https://api.experimentation.xogrp.com'
  : 'https://qa-api.experimentation.xogrp.com';
export const EXPERIMENTATION_API_KEY = IS_PRODUCTION
  ? '8e8d7680decb3dc29aa3f1b1548ec042'
  : 'dc02e640a11e9e6f97c4954e84f9b33f';
export const GIFTS_API_HOST = IS_PRODUCTION
  ? 'https://registry-gifts-api.regsvcs.theknot.com'
  : 'https://qa-registry-gifts-api.regsvcs.theknot.com';
export const MUAF_API_HOST = IS_PRODUCTION
  ? 'https://prod-registry-api-muaf-items.regsvcs.theknot.com'
  : 'https://qa-registry-api-muaf-items.regsvcs.theknot.com';
export const MAGENTO_API_HOST = IS_PRODUCTION
  ? 'https://gifts.theknot.com'
  : 'https://staging-gifts.theknot.com';
export const MEDIA_API_URL = IS_PRODUCTION
  ? 'https://media.xogrp.com/images'
  : 'https://qa-media.xogrp.com/images';
export const RIG_API_HOST = IS_PRODUCTION
  ? 'https://registry-item-gateway.regsvcs.theknot.com'
  : 'https://qa-registry-item-gateway.regsvcs.theknot.com';
export const TRACK_HOST = IS_PRODUCTION
  ? 'https://track-registry.theknot.com'
  : 'https://qa-track-registry.theknot.com';
export const TK_HOST =
  process.env.ENV === 'production'
    ? 'https://www.theknot.com'
    : 'https://qa-beta.theknot.com';
export const WWS_PRODUCT_GRID_CLICK_SOURCE_ID =
  'a2fd123d-31e3-44f9-b82e-f54d77ee2af5';
export const GVR_PRODUCT_GRID_CLICK_SOURCE_ID =
  '16e208bf-32fb-473f-91d0-f2428e1e3548';

export const GVR_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID =
  '3e4abaf2-d462-4d3c-b6ef-f37975cd4d3f';
export const WWS_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID =
  '1123465D-41F7-42C0-B55E-E5506AF35C89';

export const GVR_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID =
  '8082157b-c80a-4794-8402-47d1d86fca95';
export const WWS_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID =
  '10103C44-6F45-4FBB-9206-4DEF9B8B75D3';

export const UNIVERSAL_STEPS = {
  PRODUCT_DETAILS: 'productDetails',
  GIFT_PROMPT: 'giftPrompt',
  FULFILLMENT: 'fulfillment',
  FULFILLMENT_SUCCESS: 'fulfillmentSuccess',
};

export const MODAL_SIZES = {
  LARGE: 'lg',
  SMALL: 'sm',
};

export const EXPERIMENTS = {};
