import {useState, useEffect} from 'react';
import mapProductForAnalytics from './helpers/mapProductForAnalytics';
import useAnalytics from '../hooks/useAnalytics';
import {formatDate} from '../utils/analytics';
import {useCoupleSummary} from '../queries/couple';

const useTrackWishListProductViewed = ({memberId, member, product, index}) => {
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const {isReady} = useAnalytics();
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (isReady && !tracked) {
      window.analytics.track({
        event: 'Wishlist Product Viewed',
        properties: {
          wishlist_id: memberId,
          memberId: member?.id || null,
          currency: 'USD',
          wishlist_name: 'Transactional Registry',
          list_id: 'guest-registry',
          event_date: formatDate(coupleSummary.eventDate) || null,
          ...mapProductForAnalytics(product, index),
        },
      });
      setTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, tracked]);
};

export default useTrackWishListProductViewed;
