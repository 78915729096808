import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Body1, Body2} from '@xo-union/tk-ui-typography';
import {Button} from '@xo-union/tk-component-buttons';
import PowerReviewsStars from '@tkww/registry-component-power-reviews-stars';
import Product from '../../../../proptypes/Product';
import AmountNeeded from '../AmountNeeded';
import ProductCardImage from './ProductCardImage';
import DeliveryDetails from './DeliveryDetails';
import OverlaySpinner from '../../../OverlaySpinner';
import {
  AMOUNT_NEEDED_UNLIMITED,
  AMOUNT_NEEDED_PURCHASED,
  AMOUNT_NEEDED_UNAVAILABLE,
  CASH_FUND,
  TRANSACTIONAL_PRODUCT,
  INTERNAL_GIFT_CARD,
  UNIVERSAL_REGISTRY_PRODUCT,
  RETAILER_PRODUCT,
} from '../../../../constants';
import {
  getName,
  getStoreName,
  isUnlimitedCashFund,
  isUnlimitedGiftCard,
} from '../../../../utils/registryItem';
import ProgressBar from '../ProgressBar';
import useAddToCart from '../../../../hooks/useAddToCart';
import {ModalContext} from '../../contexts/ModalContext';
import makeTestId from '../../utils/makeTestId';
import getAmountNeeded from '../../utils/getAmountNeeded';
import {ProductsContext} from '../../contexts/ProductsContext';
import style from './style.scss';

const shouldShowPrice = ({
  price,
  product: {fundType, type, itemAttributes = []},
  storeName,
}) => {
  // CrowdRise item price and quantity is hidden because it is a special case that adds charity funds as retailer items.
  const showPrice = Number(price) !== 0 && storeName !== 'CrowdRise';

  return (
    showPrice &&
    !isUnlimitedCashFund(fundType, type) &&
    !isUnlimitedGiftCard(itemAttributes, type)
  );
};

const ProductCard = ({position, product, onClick}) => {
  const {addToCart, isAddingToCart} = useAddToCart({
    testId: (label) => makeTestId('product-grid', 'add-to-cart', label),
  });
  const {type, subtype, isUsingDenominationGifting} = product;
  const {amountNeeded, priceNeeded: price} = getAmountNeeded({
    ...product,
  });
  const {toggleModal} = useContext(ModalContext);
  const {hasAffiliateProducts, hasTKRSProducts} = useContext(ProductsContext);
  const showCta = hasTKRSProducts || (hasTKRSProducts && hasAffiliateProducts);
  const productName = getName(product);
  const isTKRSItem =
    type === TRANSACTIONAL_PRODUCT ||
    type === CASH_FUND ||
    type === INTERNAL_GIFT_CARD;
  const isAvailable =
    amountNeeded !== AMOUNT_NEEDED_UNAVAILABLE &&
    amountNeeded !== AMOUNT_NEEDED_PURCHASED;
  const storeName = getStoreName(product);
  const showPrice = shouldShowPrice({price, product, storeName});

  const handleTKRSItemClick = (e) => {
    e.stopPropagation();
    if (
      type === CASH_FUND ||
      isUsingDenominationGifting ||
      type === INTERNAL_GIFT_CARD
    ) {
      onClick();
      return;
    }

    addToCart({product, quantity: 1, source: 'product card cta', position});
    toggleModal({isVisible: false});
  };

  const hideStoreName =
    product.type === UNIVERSAL_REGISTRY_PRODUCT ||
    product.type === RETAILER_PRODUCT;

  return (
    <>
      {isAddingToCart && <OverlaySpinner isOpaque />}

      <ProductCardImage product={product} />

      <div className={style['product-details']}>
        <Body2
          className={style['product-storename']}
          data-testid={makeTestId('product-grid', 'storename')}>
          {hideStoreName ? '' : storeName}
        </Body2>

        <Body1
          as="h3"
          className={style['product-name']}
          data-testid={makeTestId('product-grid', 'name')}>
          {productName}
        </Body1>

        <PowerReviewsStars reviews={product.reviews} />

        {showPrice && !(isAvailable && isUsingDenominationGifting) && (
          <Body1
            bold
            className={style['product-price']}
            data-testid={makeTestId('product-grid', 'price')}>
            ${price}
          </Body1>
        )}

        {[TRANSACTIONAL_PRODUCT, INTERNAL_GIFT_CARD].includes(type) &&
          !isUsingDenominationGifting &&
          isAvailable && <DeliveryDetails subtype={subtype} type={type} />}

        <div className={style['card-bottom-section']}>
          {isAvailable &&
            isUsingDenominationGifting &&
            product.denominationGifting && (
              <>
                <ProgressBar
                  progress={product.denominationGifting.percentFulfilled}
                />
                <Body1 className={style['group-gifting-label']}>
                  ${product.denominationGifting.amountFulfilled} out of $
                  {product.denominationGifting.amountRequested} received
                </Body1>
              </>
            )}

          {!(isAvailable && isUsingDenominationGifting) &&
            type !== INTERNAL_GIFT_CARD && (
              <AmountNeeded
                amountNeeded={amountNeeded}
                className={style['product-amount-needed']}
              />
            )}

          {isTKRSItem && isAvailable && showCta && (
            <Button
              className={style.cta}
              color="secondary-alternative"
              onClick={handleTKRSItemClick}
              size="md">
              {amountNeeded === AMOUNT_NEEDED_UNLIMITED ||
              isUsingDenominationGifting ||
              type === INTERNAL_GIFT_CARD
                ? 'Choose Amount'
                : 'Add to Cart'}
            </Button>
          )}

          {!isTKRSItem && isAvailable && showCta && (
            <Button
              className={cx(style.cta, style.affiliate)}
              color="tertiary-alternative"
              size="md">
              See More Details
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

ProductCard.defaultProps = {
  onClick: () => {},
};

ProductCard.propTypes = {
  position: PropTypes.number.isRequired,
  product: Product.type.isRequired,
  onClick: PropTypes.func,
};

export default ProductCard;
