export const replaceAtIndex = (array, index, value) => {
  const copy = [...array];
  copy[index] = value;
  return copy;
};

export const replaceAtIndices = (array, indices, value) => {
  const copy = [...array];
  indices.forEach((index) => {
    copy[index] = value;
  });
  return copy;
};
