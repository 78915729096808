import {useContext} from 'react';
import {compose} from '@xo-union/react-css-modules';
import cx from 'classnames';
import styles from './styles.scss';
import {ModalContext} from '../../contexts/ModalContext';

export const composeClasses = ({isVariantCashFund = false} = {}) => {
  const modalClasses = compose({
    'button-container': cx(styles['button-container'], {
      [styles['card-experiment']]: isVariantCashFund,
    }),
    'close-button': styles['close-button'],
    large: cx(styles['modal-spacing'], {
      [styles['card-experiment']]: isVariantCashFund,
    }),
  });

  return modalClasses;
};

const useProductModalConfig = () => {
  const {onClose} = useContext(ModalContext);

  return {
    isVisible: true,
    props: {
      onClose,
      classes: composeClasses(),
      size: 'lg',
      'aria-describedby': 'product-details-name',
      'data-testid': 'product-grid-product-modal',
      role: 'dialog',
      'aria-modal': 'true',
    },
    composeClasses,
  };
};

export default useProductModalConfig;
