import {useQuery} from '@tanstack/react-query';
import {useContext} from 'react';
import {shallow} from 'zustand/shallow';
import {AppContext} from '../components/ProductGrid/contexts/AppContext';
import {SortFiltersContext} from '../components/ProductGrid/contexts/SortFiltersContext';
import {useCoupleSummary} from './couple';
import fetchItems from '../components/ProductGrid/utils/fetchItems';
import getSortFilters from '../components/ProductGrid/utils/getSortFilters';
import useGlobalProductStore from '../hooks/useGlobalProductStore';
import {getShowCashRegistry} from '../utils/registry';
import {replaceAtIndex, replaceAtIndices} from '../utils/array';
import {RETAILER_PRODUCT, TRANSACTIONAL_ITEMS_API_HOST} from '../constants';

const REFETCH_INTERVAL_MS = 30000;

const mapStores = (stores = []) =>
  stores.map(({store}) => ({
    label: store,
    value: store,
  }));

const removeDuplicateRetailerItems = (products = []) => {
  const uniqueProducts = [];
  products.forEach((product) => {
    const hasDuplicate = uniqueProducts.find(
      (p) => p.type === RETAILER_PRODUCT && p.id === product.id,
    );

    if (!hasDuplicate) {
      uniqueProducts.push(product);
    }
  });
  return uniqueProducts;
};

export const useDenominationGiftingItems = () => {
  const {memberId} = useContext(AppContext);
  const query = useQuery(
    ['denominationGiftingItems', memberId],
    async () => {
      const res = await fetch(
        `${TRANSACTIONAL_ITEMS_API_HOST}/registry/${memberId}/denominational-items`,
      );
      if (!res.ok) {
        throw new Error('Failed to fetch denomination gifting items');
      }
      return res.json();
    },
    {enabled: !!memberId},
  );
  return {...query, isFetched: query.isFetched || !memberId};
};

export const useProducts = (memberId, coupleId) => {
  const {initialProducts, initialStores, sourceAffiliateId} =
    useContext(AppContext);
  const {currentSortFilters = getSortFilters()} =
    useContext(SortFiltersContext);
  const {data: coupleSummary = {}} = useCoupleSummary(memberId || coupleId);
  const {setStores, setHasDoneFirstFetch} = useGlobalProductStore(
    (state) => ({
      setStores: state.setStores,
      setHasDoneFirstFetch: state.setHasDoneFirstFetch,
    }),
    shallow,
  );

  const {data: denominationalItems = [], isFetched: hasFetchedDenomItems} =
    useDenominationGiftingItems();
  const {registries} = coupleSummary;
  const showCash = getShowCashRegistry(coupleSummary);

  const {...productsQuery} = useQuery(
    [
      'products',
      memberId,
      sourceAffiliateId,
      currentSortFilters,
      denominationalItems,
    ],
    async () => {
      let response = {
        items: [],
        stores: [],
        hasTKRSProducts: false,
        hasAffiliateProducts: false,
      };

      if (registries) {
        response = await fetchItems({
          registries,
          showCash,
          showGiftCards: true,
          memberId,
          ...currentSortFilters,
        });
      }

      const {
        items: fetchedItems = [],
        stores: fetchedStores = [],
        hasTKRSProducts,
        hasAffiliateProducts,
      } = response;

      let finalItems = fetchedItems;

      denominationalItems.forEach((denominationalItem) => {
        const giftcard = finalItems.find((i) => {
          return (
            denominationalItem?.variantSkus?.includes(i.sku) &&
            i.isUsingDenominationGifting
          );
        });

        if (!giftcard) return;

        const indicesOfAllGiftCardVariants = finalItems
          .map((item, index) => {
            if (denominationalItem?.variantSkus?.includes(item.sku)) {
              return index;
            }
            return false;
          })
          .filter((index) => index !== false);

        const {amountFulfilled, amountRequested} = denominationalItem;

        const item = {
          ...giftcard,
          denominationGifting: {
            percentFulfilled: (amountFulfilled * 100) / amountRequested,
            amountFulfilled,
            amountRequested,
          },
        };
        finalItems = replaceAtIndices(
          finalItems,
          indicesOfAllGiftCardVariants,
          false,
        );
        finalItems = replaceAtIndex(
          finalItems,
          finalItems.indexOf(false),
          item,
        ).filter((i) => i !== false);
      });

      /**
       * HOTFIX: Remove duplicate retailer items
       * This is a temporary fix to remove duplicate retailer items
       * that are returned from the API. - 2/8/2023
       */
      const dedupedItems = removeDuplicateRetailerItems(finalItems);

      return {
        products: dedupedItems,
        hasTKRSProducts,
        hasAffiliateProducts,
        stores: mapStores(fetchedStores),
      };
    },
    {
      enabled:
        Object.keys(coupleSummary || {}).length > 0 && hasFetchedDenomItems,
      refetchInterval: REFETCH_INTERVAL_MS,
      initialData: {products: initialProducts, stores: initialStores},
      onSuccess: (data) => {
        setStores(data.stores);
        setHasDoneFirstFetch();
      },
    },
  );
  return productsQuery;
};

export default useProducts;
