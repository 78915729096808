import {useExperiment} from '@tkww/reg-flipper-sdk';
import {useAtom} from 'jotai';
import {segmentAnonymousIdAtom} from '../../../../../hooks/useAnalytics';
import {
  CONTROL_ID,
  EXPERIMENT_ID,
  ASSIGNMENT_ID_TO_GREETING_CARD_OPTIONS,
} from './greetingCardConstants';

const useGreetingCardExperiment = () => {
  const [segmentAnonymousId] = useAtom(segmentAnonymousIdAtom);

  const {isLoading, variant: assignmentId} = useExperiment({
    isEnabled: !!segmentAnonymousId,
    experimentId: EXPERIMENT_ID,
    bucketingId: segmentAnonymousId,
  });

  const greetingCardOptions =
    ASSIGNMENT_ID_TO_GREETING_CARD_OPTIONS[assignmentId] ||
    ASSIGNMENT_ID_TO_GREETING_CARD_OPTIONS[CONTROL_ID];

  return {
    isLoading,
    isControl: assignmentId && assignmentId === CONTROL_ID,
    greetingCardOptions,
  };
};

export default useGreetingCardExperiment;
