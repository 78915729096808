import React, {useContext, Suspense, lazy} from 'react';
import {useCartItemCount, useGetCartId} from '@tkww/reg-shared-ui-cart-icon';
import {AppContext} from '../../contexts/AppContext';
import GuestCashFundContent from './GuestCashFundContent';
import {
  TK_HOST,
  CASH_FUND,
  INTERNAL_GIFT_CARD,
  TRANSACTIONAL_PRODUCT,
  UNIVERSAL_REGISTRY_PRODUCT,
} from '../../../../constants';
import {ProductsContext} from '../../contexts/ProductsContext';
import OverlaySpinner from '../../../OverlaySpinner';
import trackCashFund from '../../utils/analytics/trackCashFund';
import {useCartItems} from '../../../../mutations/magento';
import trackFulfillment from '../../utils/analytics/trackFulfillment';
import trackUniversalProductClick from '../../utils/analytics/trackUniversalProductClick';
import {useCoupleSummary} from '../../../../queries/couple';
import {useMember} from '../../../../queries/member';
import useTrackWishListProductViewed from '../../../../analytics/trackWishListProductViewed';

const TransactionalContent = lazy(() => import('../TransactionalContent'));
const UniversalContent = lazy(() => import('../UniversalContent'));

const ProductModal = () => {
  const {application, isMobile, memberId, fianceFirstName, firstName} =
    useContext(AppContext);
  const {data: {id: coupleId} = {}} = useCoupleSummary(memberId);
  const {currentProduct, hasTKRSProducts, hasAffiliateProducts} =
    useContext(ProductsContext);
  const {data: cartId} = useGetCartId();
  const {data: member} = useMember();
  const {data: cartItemCount = 0} = useCartItemCount({cartId});
  const {data: cart} = useCartItems({cartId}) || [];

  const shouldDisplayAlert =
    (hasTKRSProducts || hasAffiliateProducts) && !!cartItemCount;

  const experimentUrl = `registry=${encodeURIComponent(
    window.location.origin + window.location.pathname,
  )}`;
  // add user ability to click 'back to registry' if they are also in the checkout experiment for breadcrumbs
  const cartPath = `${TK_HOST}/registry/store/cart/checkout?cart_id=${cartId}&${experimentUrl}`;

  useTrackWishListProductViewed({
    memberId,
    member,
    product: currentProduct,
    index: currentProduct.position,
  });

  return (
    <Suspense fallback={<OverlaySpinner />}>
      {currentProduct.type === CASH_FUND && (
        <GuestCashFundContent
          currentProduct={currentProduct}
          member={member}
          coupleId={coupleId}
          fianceFirstName={fianceFirstName}
          firstName={firstName}
          cartRedirectPath={cartPath}
          showCartLeakageAlert={shouldDisplayAlert}
          cartItems={cart}
          trackCashFund={(selection) =>
            trackCashFund(
              isMobile,
              application,
              currentProduct,
              selection,
              cartId,
            )
          }
          application={application}
        />
      )}

      {currentProduct.type === UNIVERSAL_REGISTRY_PRODUCT && (
        <UniversalContent
          trackFulfillment={(name, email) =>
            trackFulfillment(isMobile, application, currentProduct, name, email)
          }
          trackProductClick={() =>
            trackUniversalProductClick(isMobile, application, currentProduct)
          }
        />
      )}

      {[TRANSACTIONAL_PRODUCT, INTERNAL_GIFT_CARD].includes(
        currentProduct.type,
      ) && <TransactionalContent />}
    </Suspense>
  );
};

export default ProductModal;
