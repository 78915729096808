const envSwitch = (qaValue, prodValue) => {
  if (prodValue === undefined) {
    return qaValue;
  }

  return process.env.ENV === 'production' ? prodValue : qaValue;
};

export const EXPERIMENT_ID = envSwitch(
  '7cb7e7a8-84ea-4e52-8ac0-84ed9fb05ca4',
  'a8aee42e-6172-4882-8a9c-93b9d177ffdf',
);
export const CONTROL_ID = envSwitch(
  'bca60777-c46f-4009-afbe-5a2bf6b9ea09',
  '3bdc27ee-4418-4cfd-b1f3-d69fc4250fc1',
);
const PAPER_LOW_PRICE_ID = envSwitch(
  'e0837b9d-c640-4e81-88db-13d00d29357b',
  'b86f247f-40bd-446b-a61f-2b052cfc4805',
);
const PAPER_MID_PRICE_ID = envSwitch(
  'f3303980-e825-4fa9-a546-bfabab7fdb30',
  '84767fc9-152c-4f93-856b-dbe24ce687ec',
);
const PAPER_HIGH_PRICE_ID = envSwitch(
  '48504c38-ca9e-4315-8625-09593cbd4223',
  'cce6f2f9-5fac-442f-96f0-6a0d1c6ffadd',
);
const ECARD_LOW_PRICE_ID = envSwitch(
  'e7e12b84-4811-4a0d-adf3-adc64a5d1fe7',
  '1a4a6012-5a64-401b-a835-0f83ddba92e3',
);
const ECARD_MID_PRICE_ID = envSwitch(
  '4bc7632e-cd98-4f3b-b48a-0c0f17f0876b',
  'aef43ce1-1e40-4d00-b624-b4d84992e7fb',
);
const ECARD_HIGH_PRICE_ID = envSwitch(
  '8a6c15f2-0e3e-4c9f-92af-2b488c8589c4',
  '4a6c452f-d84e-4390-9bc8-d8621940c832',
);
const BOTH_CARDS_LOW_PRICE_ID = envSwitch(
  '7fdb231c-3ecc-458d-a059-f30b74100bde',
  'f0a48e7f-882b-4a5e-b4f2-31161c9f2452',
);
const BOTH_CARDS_MID_PRICE_ID = envSwitch(
  '6f5629f3-e608-4ded-b949-b876b6004738',
  '44761663-8481-427f-8b2b-7fb6a2865a50',
);
const BOTH_CARDS_HIGH_PRICE_ID = envSwitch(
  'cecab2b5-e0e4-4017-a1b0-7871674f2bf5',
  '8cadb3a0-1004-460c-b214-a3efdb2f5101',
);

export const ASSIGNMENT_ID_TO_GREETING_CARD_OPTIONS = {
  [CONTROL_ID]: {
    isInPaperCardVariant: false,
    paperCardPrice: 0,
    isInEcardVariant: false,
    ecardPrice: 0,
  },
  [PAPER_LOW_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 8.99,
    isInEcardVariant: false,
    ecardPrice: 0,
  },
  [PAPER_MID_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 11.99,
    isInEcardVariant: false,
    ecardPrice: 0,
  },
  [PAPER_HIGH_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 13.99,
    isInEcardVariant: false,
    ecardPrice: 0,
  },
  [ECARD_LOW_PRICE_ID]: {
    isInPaperCardVariant: false,
    paperCardPrice: 0,
    isInEcardVariant: true,
    ecardPrice: 2.99,
  },
  [ECARD_MID_PRICE_ID]: {
    isInPaperCardVariant: false,
    paperCardPrice: 0,
    isInEcardVariant: true,
    ecardPrice: 4.99,
  },
  [ECARD_HIGH_PRICE_ID]: {
    isInPaperCardVariant: false,
    paperCardPrice: 0,
    isInEcardVariant: true,
    ecardPrice: 6.99,
  },
  [BOTH_CARDS_LOW_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 8.99,
    isInEcardVariant: true,
    ecardPrice: 2.99,
  },
  [BOTH_CARDS_MID_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 11.99,
    isInEcardVariant: true,
    ecardPrice: 4.99,
  },
  [BOTH_CARDS_HIGH_PRICE_ID]: {
    isInPaperCardVariant: true,
    paperCardPrice: 13.99,
    isInEcardVariant: true,
    ecardPrice: 6.99,
  },
};
